<template>
  <div class="space-y-5 py-6">
    <h3 class="h3 px-6">Monitoring Groups</h3>
    <div
      class="space-y-6 px-6 base-scrollbar overflow-y-auto"
      style="height: calc(80vh - 80px)"
    >
      <div
        v-for="monitoringGroup in computedMonitoringGroups"
        :key="monitoringGroup.id"
      >
        <monitoring-group-card
          :monitoring-group="monitoringGroup"
          class="shadow-bb-shadow hover:border-bb-brand-purple cursor-pointer"
          :class="selectedMonitoringGroup.id === monitoringGroup.id ? 'border border-bb-brand-purple ' : null"
          @select-monitoring-group="switchMonitoringGroup(monitoringGroup)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringGroupCard from '@/views/site/search/competitor_monitoring_v2/components/MonitoringGroupCard.vue'

export default {
  name: 'MonitoringGroups',
  components: {
    MonitoringGroupCard,
  },
  props: {
    monitoringGroups: {
      type: Array,
      default: () => [],
    },
    selectedMonitoringGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    computedMonitoringGroups() {
      return structuredClone(this.monitoringGroups).map(group => {
        if (group.geoLocations.length > 1) {
          group.geoLocations.unshift({
            name: 'All Locations',
            countryCode: 'all-locations',
          })
        }

        return group
      })
    },
  },
  methods: {
    switchMonitoringGroup(monitoringGroup) {
      this.$emit('select-monitoring-group', monitoringGroup)
    },
  },
}
</script>

<style scoped lang="scss"></style>
